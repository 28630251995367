import React from 'react';
import './ListingCard.scss';

import { ReactComponent as HomeCard } from '../../assets/img/cardHome.svg';
import { ReactComponent as ApartmentCard } from '../../assets/img/cardApartment.svg';
import { ReactComponent as BedCard } from '../../assets/img/cardBed.svg';
import { ReactComponent as BathCard } from '../../assets/img/cardBath.svg';
import { ReactComponent as SizeCard } from '../../assets/img/cardSize.svg';
import AddressComponent from './AddressComponent';

const ListingCard = ({ card, panorama, setPanorama, totalPay, avarege, proptype }) => {
  const splitAddress = card.address.split(', ');
  let beforeComma = splitAddress[0];
  let afterComma = splitAddress.slice(1).join(', ');

  let marketAvaragePercent = Math.round(((card.rawPrice - avarege) / avarege) * 100);
  let marketBudgetPercent = Math.round(((card.rawPrice - totalPay) / totalPay) * 100);

  return (
    <div className="cardContainer">
      <div className="cardHeader">
        <div className="cardHeaderAddress">
          <AddressComponent content={beforeComma} />
          <AddressComponent content={afterComma} />
        </div>
        {proptype === 'Apt/Condo' ? <ApartmentCard /> : <HomeCard />}
      </div>
      <div className="cardBudget">{card.price}/month</div>
      <ul className="cardData">
        <li>
          <BedCard />
          {card.bedrooms}
        </li>
        <li>
          <BathCard />
          {card.bathrooms}
        </li>
        <li>
          <SizeCard />
          {card.size} s.f.
        </li>
      </ul>
      <div className="cardInfo">
        <span style={{ color: `${marketAvaragePercent < 0 ? '#74B566' : '#C24040'}` }}>
          {Math.abs(marketAvaragePercent)}%
        </span>
        {marketAvaragePercent < 0 ? 'below' : 'above'} market average
      </div>
      <div className="cardInfo">
        <span style={{ color: `${marketBudgetPercent < 0 ? '#74B566' : '#C24040'}` }}>
          {Math.abs(marketBudgetPercent)}%
        </span>
        {marketBudgetPercent < 0 ? 'below' : 'above'} your budget
      </div>
      <div className="cardButtons">
        <button className="cardBtn cardBtnContacts" disabled>
          Contacts
        </button>
        <button
          className="cardBtn cardBtnMap"
          onClick={() => {
            if (
              JSON.stringify(panorama) ==
              JSON.stringify({ lat: card.latitude, lng: card.longitude })
            ) {
              setPanorama(false);
            } else {
              setPanorama({ lat: card.latitude, lng: card.longitude });
            }
          }}>
          {JSON.stringify(panorama) == JSON.stringify({ lat: card.latitude, lng: card.longitude })
            ? 'Go back to map'
            : 'Show on map'}
        </button>
      </div>
      <div className="cardTime">Published on {card.listDate}</div>
    </div>
  );
};

export default ListingCard;
